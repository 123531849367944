<template>
    <div class="contenedor-provedor">
        <div class="space-header"></div>          
        <TabsButton 
            :arraySessions="['Agregar Proveedor','Exportar']"
            :firstSelect="false"
            :vModel='(data) => tabFormsMain = data'
            :bulReset='bulResetTabFormsMain'
            :vResetBul='() => bulResetTabFormsMain = false'
            type="sessions"
        />
        <FormCustom
            typeContainer="window"
            title="Agregar Proveedor"
            textSecButton="Agregar"
            :actionSecButton="(data)=>agregarProvedor(data, true)"
            widthWindowForm="400px"
            :exitForm='() => bulResetTabFormsMain = true'
            :bulWindow="tabFormsMain['Agregar Proveedor']"
            :limitInputsInSection="4"
            :objectForm="[
                {
                    type: 'text',
                    label: 'Nombre',
                    name: 'Nombre_provedor',
                    
                },
                {
                    type: 'text',
                    label: 'Teléfonos',
                    name: 'Telefonos',
                    
                },
                {
                    type: 'text',
                    label: 'Transporte',
                    name: 'Transporte',
                    
                },
                {
                    type: 'number',
                    label: 'Regimen IVA',
                    name: 'Regimen_Iva',                    
                    
                },
                {
                    type: 'text',
                    label: 'Contacto',
                    name: 'Contacto',
                    
                },
                {
                    type: 'text',
                    label: 'Tipo Crédito',
                    name: 'Tipo_credito',
                    
                },
                {
                    type: 'text',
                    label: 'Cuenta Domiciliaria',
                    name: 'Cuenta_domiciliaria',
                    
                },
                {
                    type: 'text',
                    label: 'Dirección',
                    name: 'Direccion',
                    
                },
                {
                    type: 'number',
                    label: 'RIF',
                    name: 'Rif',
                    
                },
                {
                    type: 'number',
                    label: 'Límite De Crédito',
                    name: 'Limite_credito',
                    
                },
                {
                    type: 'number',
                    label: 'Descuento %',
                    name: 'Descuento',
                    
                },
                {
                    type: 'number',
                    label: 'Retención %',
                    name: 'Retencion',
                    
                },
                {
                    type: 'text',
                    label: 'Banco Domiciliario',
                    name: 'Banco_domiciliario',
                    
                },
                {
                    type: 'text',
                    label: 'Email',
                    name: 'Email',
                    
                },
                {
                    type: 'text',
                    label: 'Zona',
                    name: 'Zona',
                    
                },
            ]"
        />
        <!-- interfaz tablas -->
        <div class="contenedor-historial">
            <TabsButton
                :arraySessions="['Proveedores']"
                :firstSelect="true"
                :vModel='(data) => tabTablas = data'
                type="sessionsCards"
            />
            <Table 
                :heightTable=400
                :dataSourceFilter="provedoresFiltros"
                :actionEdit="(data) => {
                    memoriaProvedor = data
                    bulEditaProvedor = true
                }"
                :actionDelete="(data) =>{
                    memoriaProvedor = data
                    bulQuestionDelete = true
                }"
                :boolVisible="tabTablas['Proveedores']"
                :stateData="{
                    data: 'provedores', 
                    dataFilterSet: 'SET_PROVEDORES_FILTROS', 
                }"
                :arrayNameColumns="[
                    {name:'Nombre', reference: 'Nombre_provedor', type: 'text', width: 200},
                    {name:'Retencion', reference: 'Retencion', type: 'number', width: 200},
                    {name:'Tipo', reference: 'Tipo_credito', type: 'text', width: 200},
                    {name:'Banco Domiciliario', reference: 'Banco_domiciliario', type: 'text', width: 200},
                    {name:'Fechas', reference: 'createdAt', type: 'date', width: 200},
                    {name:'Codigos contables', reference: 'Codigo_provedor', type: 'text', width: 200},
                    {name:'Observaciones', reference: 'Observaciones', type: 'text', width: 200},
                ]"
            />
        </div>
        <!-- interfaz editar -->
        <ModalCustom
            :bulModal="bulEditaProvedor"
            :exitModal='() => bulEditaProvedor = false'
            :widthCard="'100%'"
        >
            <FormCustom
                textSecButton="Editar"
                typeStyle="column"
                :isOneButton="true"
                :objectForm="[
                    {
                        type: 'text',
                        label: 'Nombre',
                        name: 'Nombre_provedor',
                        valueDefault: memoriaProvedor.Nombre_provedor
                    },
                    {
                        type: 'text',
                        label: 'Teléfonos',
                        name: 'Telefonos',
                        valueDefault: memoriaProvedor.Telefonos || '1212121'
                    },
                    {
                        type: 'text',
                        label: 'Transporte',
                        name: 'Transporte',
                        valueDefault: memoriaProvedor.Transporte
                    },
                    {
                        type: 'number',
                        label: 'Regimen IVA',
                        name: 'Regimen_Iva',                    
                        valueDefault: memoriaProvedor.Regimen_Iva
                    },
                    {
                        type: 'text',
                        label: 'Contacto',
                        name: 'Contacto',
                        valueDefault: memoriaProvedor.Contacto
                    },
                    {
                        type: 'text',
                        label: 'Tipo Crédito',
                        name: 'Tipo_credito',
                        valueDefault: memoriaProvedor.Tipo_credito
                    },
                    {
                        type: 'text',
                        label: 'Cuenta Domiciliaria',
                        name: 'Cuenta_domiciliaria',
                        valueDefault: memoriaProvedor.Cuenta_domiciliaria
                    },
                    {
                        type: 'text',
                        label: 'Dirección',
                        name: 'Direccion',
                        valueDefault: memoriaProvedor.Direccion
                    },
                    {
                        type: 'number',
                        label: 'RIF',
                        name: 'Rif',
                        valueDefault: memoriaProvedor.Rif
                    },
                    {
                        type: 'number',
                        label: 'Límite De Crédito',
                        name: 'Limite_credito',
                        valueDefault: memoriaProvedor.Limite_credito
                    },
                    {
                        type: 'number',
                        label: 'Descuento %',
                        name: 'Descuento',
                        valueDefault: memoriaProvedor.Descuento
                    },
                    {
                        type: 'number',
                        label: 'Retención %',
                        name: 'Retencion',
                        valueDefault: memoriaProvedor.Retencion
                    },
                    {
                        type: 'text',
                        label: 'Banco Domiciliario',
                        name: 'Banco_domiciliario',
                        valueDefault: memoriaProvedor.Banco_domiciliario
                    },
                    {
                        type: 'text',
                        label: 'Email',
                        name: 'Email',
                        valueDefault: memoriaProvedor.Email
                    },
                    {
                        type: 'text',
                        label: 'Zona',
                        name: 'Zona',
                        valueDefault: memoriaProvedor.Zona
                    },
                ]"
                :buttons="[,
                    {
                        text: 'Editar',
                        style: {
                            marginTop: '-40px',
                            width: '150px',
                        }
                    }
                ]"
                :actionSecButton="(data)=>{
                    editarProvedor({...memoriaProvedor, ...data})
                    bulEditaProvedor = false
                }"
            />
        </ModalCustom>          
        <!-- interfaz seguro borrar provedor -->
        <ModalCustom
            :bulModal="bulQuestionDelete"
            :exitModal='() => bulQuestionDelete = false'
            widthCard="600px"
            title="¿Seguro que quieres eliminar este proveedor?"
        >
            <div class="contenedor-seguro-boton2 d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="()=>{
                    eliminarProvedor(memoriaProvedor)
                    bulQuestionDelete = false
                }">
                    Eliminar
                </button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulQuestionDelete = false" >Cancelar</button>
            </div>
        </ModalCustom>        
    </div>
</template>

<script>
    import Footer from "../../../components/Footer.vue"
    import {mapState, mapActions} from "vuex"
    import FormCustom from "../../../components/FormCustom.vue"
    import Table from "../../../components/Table.vue"
    import TabsButton from "../../../components/TabsButton.vue"
    import ModalCustom from "../../../components/ModalComponent.vue"
    import FilterExport from '../../../components/FilterExport.vue'    

    export default {
        name: "PROVEDOR",
        components: {
            TabsButton,
            Table,
            ModalCustom,
            FormCustom,
            Footer,
            FilterExport,
        },
        data(){
            return{
                //tabs
                tabFormsMain: {},
                tabTablas: {},
                bulResetTabFormsMain: false,
                bulEditaProvedor: false,
                memoriaProvedor: {}
            }
        },
        methods:{
            ...mapActions([
                "someFieldEmpty", 
                "excessLimitCharacters", 
                "requestProvedores", 
                "agregarProvedorVuex", 
                "editarProvedorVuex", 
                "eliminarProvedorVuex"
            ]),
            async agregarProvedor (data, bul) {
                const {
                    Codigo_provedor, 
                    Nombre_provedor,
                    Rif,
                    Direccion,
                    Tipo_de_provedor,
                    Limite_credito,
                    Transporte, 
                    Descuento, 
                    Regimen_Iva, 
                    Retencion, 
                    Contacto, 
                    Banco_domiciliario, 
                    Tipo_credito, 
                    Email, 
                    Cuenta_domiciliaria, 
                    Zona, 
                    Observaciones,
                } = data
                
                //ver que los campos no esten vacios
                const camposVacios = await this.someFieldEmpty({fields: data})

                //campos con limites en caracteres
                const excedenLimite = await this.excessLimitCharacters({
                    fields:{
                        Nombre_provedor, 
                        Direccion, 
                        Tipo_de_provedor, 
                        Transporte, 
                        Contacto, 
                        Banco_domiciliario, 
                        Tipo_credito, 
                        Cuenta_domiciliaria, 
                        Zona
                    },
                    limit: 30
                })

                if(camposVacios || excedenLimite) return

                const provedorData = {
                    ...data,
                    Codigo_provedor: "no",
                    Tipo_de_provedor: "ninguno",
                    Observaciones: "nada",
                    token: localStorage.getItem("token"),
                    tokenGestion: localStorage.getItem("token2")
                }

                await this.agregarProvedorVuex(provedorData)
            },
            async editarProvedor(data){
                const { 
                    Codigo_provedor, 
                    Nombre_provedor,
                    Rif,
                    Direccion,
                    Tipo_de_provedor,
                    Limite_credito,
                    Transporte, 
                    Descuento, 
                    Regimen_Iva, 
                    Retencion, 
                    Contacto, 
                    Banco_domiciliario, 
                    Tipo_credito, 
                    Email, 
                    Cuenta_domiciliaria, 
                    Zona, 
                    Observaciones,
                } = data
                
                //ver que los campos no esten vacios
                const camposVacios = await this.someFieldEmpty({fields: data})

                //campos con limites en caracteres
                const excedenLimite = await this.excessLimitCharacters({
                    fields:{
                        Nombre_provedor, 
                        Direccion, 
                        Tipo_de_provedor, 
                        Transporte, 
                        Contacto, 
                        Banco_domiciliario, 
                        Tipo_credito, 
                        Cuenta_domiciliaria, 
                        Zona
                    }, 
                    limit: 30
                }) 

                if(camposVacios || excedenLimite || Observaciones.length > 60) return

                const editarProvedorData = {...data, token: localStorage.getItem("token"), tokenGestion: localStorage.getItem("token2")}

                await this.editarProvedorVuex(editarProvedorData)
            },
            async eliminarProvedor(data){
                const dataProvedor = {
                    Id_provedor: data.Id_provedor,
                    token: localStorage.getItem("token"),
                    tokenGestion: localStorage.getItem("token2")
                }
                
                await this.eliminarProvedorVuex(dataProvedor)
            },
        },
        async mounted(){
            await this.requestProvedores()
        },
        computed:{
        ...mapState(["token", "server", "provedores", "provedoresFiltros"]),
        }
    }
</script>

<style scoped>
    .fondo-degradado-recover{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
    }
    .contenedor-sesiones-provedor{
        width: 100%;
        margin-top:80px;
        height: 100px;
    }
    .space-header{
        height: 80px;
        width: 100%;
    }
    .boton-sesiones{
        width: 15%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 26px;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        padding-top:15px ;
        padding-bottom:15px ;
        border-radius: 16px;
        cursor: pointer;
        border-bottom:0px solid #110133;
        transition: 0.2s ease-in-out;
    }
    .contenedor-provedor{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .entrada,.salida{
        width: 40%;
    }
    .texto-check{
        margin-top: 6px;
    }
    .contenedor-botones{
        margin-top:50px;
        width: 100%;
    }
    .button-exportar{
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        width: 30%;
        margin-left: 0%;
    }
    .contenedor-historial{
        position:relative;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 20px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 90%;
        margin-left:5%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 20px;
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 50px;
        margin-bottom: 10px;
    }
    .caja-filtro{
        background:rgba(196,196,196,0.25);
        width: 100px;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        left: 20px;
        top: 20px;
        cursor:pointer;
        transition: all 1s;
    }
    .caja-filtros{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 100px;
        margin-top:40px;
        border-radius:0px 10px 10px 10px;
        transition: all 1s;
        overflow: hidden;
    }
    .simboloIzquierda{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: 7.5px;
        transition: all 1s;
    }
    .simboloDerecha{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: -3.5px;
        transition: all 1s;
    }
    .margin-izquierda{
        margin-left:4%;
    }
    .input,.input2,.input3{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 30px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
    }
    .input2{
        margin-bottom: 50px;
        width: 70%;
        margin-left:15%;
    }
    .input3{
        margin-bottom: 40px;
    }
    .label,.label2{
        font-family: Rubik;
        font-weight:bold ;
    }
    .label2{
        margin-left:15%;
    }
    .caja-enunciado{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 50px;
        border-radius:10px;
        transition: all 1s;
        margin-top:15px;
    }
    .checkbox,.checkbox2{
        width: 20px;
        height: 20px;
        border-radius:50%;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
    }
    .caja,.caja2{
        width: 12px;
        height: 12px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:50%;
    }
    .exportar-informacion{
        width: 60%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
    }
    .cuadro-filtro-seleccionado{
        background: linear-gradient(153.24deg, rgba(0, 145, 142, 0.4) 9.04%, rgba(255, 255, 255, 0) 100%);
        width: 130px;
        padding:5px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        border-radius:10px ;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2), inset 0 0 5px white;
        margin-top:10px;
        margin-bottom: 25px;
    }
</style>